#about {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15%;
    /* padding-right: 25%; */
    min-height: 60vh;
    padding-top: -10%;
  }

.title {
    font-family: "Poppins", sans-serif;
    color: var(--pink);
    font-size: 60px;
    /* font-weight: bold; */
  }
  
.about-desc {
    font-family: "Poppins", sans-serif;
    color: var(--slate);
    max-width: 80%;
    font-size: 22px;
    text-align: justify;
    margin: 0;
  }
  
@media only screen and (max-width: 600px) {

    #about {
        padding-left: unset;
        padding-top: 10%;
        min-height: unset;
        margin: 0;
        align-items: center;
        flex-direction: column;
    }
    
    .title {
        font-size: 36px;
    }
  
    .about-desc {
        font-size: 18px;
        text-align: center;
        max-width: unset;
        padding-bottom: 40px;
      }

}