#home {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15%;
    padding-top: 10%;
    min-height: 100vh;
  }

.name {
    font-family: "Poppins", sans-serif;
    color: var(--pink);
    font-size: 86px;
    /* font-weight: bold; */
}

.intro-subtitle {
    font-family: "Poppins", sans-serif;
    color: var(--slate);
    font-size: 27px;
    max-width: 80%;
    text-align: justify;
    /* font-weight: bold; */
}

.intro-desc {
    color:var(--slate);
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    max-width: 80%;
    text-align: justify;
    /* padding-bottom: 50px;
    padding-right:200px;
    padding-left: 200px; */
}

a:link {
    color: pink;
    background-color: transparent;
    /* text-decoration: none; */
  }
  
  a:visited {
    color: pink;
    background-color: transparent;
    /* text-decoration: none; */
  }
  
  a:hover {
    color: red;
    background-color: transparent;
    text-decoration: underline;
  }
  
  a:active {
    color: yellow;
    background-color: transparent;
    text-decoration: underline;
  }

@media only screen and (max-width: 600px) {

    #home {
        padding-left: unset;
        padding-top: 10%;
        min-height: unset;
        margin: 0;
        align-items: center;
        flex-direction: column;
    }
    
    .name {
        font-size: 46px;
    }
  
    .intro-desc {
        font-size: 18px;
        text-align: center;
        max-width: unset;
        padding-bottom: 40px;
      }
}