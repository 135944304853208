#projects {
    padding-left: 15%;
    padding-right: 15%;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
  }

.projects-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    column-gap: 10px;
    row-gap:75px;
  }

@media (max-width: 1080px) {
    .projects-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }

.projects-card {
    position: relative;
    cursor: default;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    padding: 2rem 1.75rem;
    background-color: var(--lighter-blue-green);
    border-radius: 16px;
  }

.card-title {
    font-family: "Poppins", sans-serif;
    /* color: var(--pink); */
    font-size: 25px;
    font-weight: bold;
  }

.card-desc {
    font-family: "Poppins", sans-serif;
    color: var(--slate);
    font-size: 16px;
    padding-top: 10px;
  }

.card-tech {
    font-family: "Poppins", sans-serif;
    color: var(--slate);
    font-size: 16px;
    padding-top: 10px;
  }

.external-link1 {
    font-family: Poppins, sans-serif;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    left: 1rem;
    color: var(--slate);
    font-size: 14px;
  }

.external-link2 {
    font-family: Poppins, sans-serif;
    position: absolute;
    bottom: 3rem;
    right: 1rem;
    left: 1rem;
    color: var(--slate);
    font-size: 14px;
  }

.section-header {
    padding-bottom: 20px;
    padding-top: 100px;
  }

.section-title {
    font-family: "Poppins", sans-serif;
    font-size: 60px;
    color: var(--pink);
    padding-left: 3%;
  }

.card-image {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
  }

@media only screen and (max-width: 600px) {

    #projects {
        padding-left: 10%;
        padding-top: 10%;
        min-height: unset;
        margin: 0;
    }
    
    .section-title {
        font-size: 36px;
        padding-left: 15%;
    }

    .card-title {
        font-size: 20px;
    }


}
